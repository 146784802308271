import React from 'react';
import { Helmet } from 'react-helmet';
import { Typography } from 'amphitheatre';

import * as Styled from '../pages-styles/shared.styled';
import {
  BackgroundWrapper,
  BLCircle,
  TLCircle,
  BRBigCircle,
  BRSmallCircle,
} from '../components/Background';
import Layout from '../components/Layout';

import { Columns } from '../pages-styles/shared.styled';
import { UnorderedList } from '../pages-styles/engineeringservices.styled';

import ImageNoCaption from '../components/ImageNoCaption';
import image1 from '../images/engineering-services/engineeringservices1.jpeg';
import image2 from '../images/engineering-services/engineeringservices2.jpeg';

const { Header, Paragraph } = Typography;
const EngineeringServices: React.FunctionComponent = () => {
  return (
    <Layout>
      <Helmet>
        <title>Wolfpack - Engineering Services</title>
      </Helmet>
      <BackgroundWrapper>
        <BLCircle />
        <TLCircle />
        <BRBigCircle />
        <BRSmallCircle />
      </BackgroundWrapper>
      <Styled.Container>
        <Header className="page-header">ENGINEERING SERVICES</Header>
        <Paragraph style={{ marginBottom: '2.5rem', lineHeight: '1.5rem' }}>
          Our members’ skills are each unique with valuable expertise in their
          chosen field and an ability to deliver the highest quality, and time
          and attention to detail. The skills of our Wolfpack members are
          available on request. 
        </Paragraph>
        <Columns style={{ alignItems: 'start' }}>
          <div style={{ flexBasis: '40%' }}>
            <ImageNoCaption
              altText="Spiral Blue showing their printed circuit board"
              src={image1}
            />
            <ImageNoCaption
              altText="Sperospace working on hardware and software"
              src={image2}
            />
          </div>
          <div style={{ marginLeft: '2rem', flexBasis: '55%' }}>
            <Header level={3}>Skills include but are not exclusive to: </Header>
            <Paragraph>
              <UnorderedList>
                <li>Model Based Systems Engineering</li>
                <li>ConOps Design</li>
                <li>Advanced Data Analysis</li>
                <li>Spacecraft and mission design</li>
                <li>Space-grade electronics design and manufacture</li>
                <li>CFD and FEA Simulation</li>
                <li>Aerospace design and development</li>
                <li>Robotics and mechatronics support</li>
                <li>Mechanical design and manufacture</li>
                <li>Payload software development</li>
                <li>Web application design and implementation</li>
                <li>
                  Machine learning algorithm and infrastructure design and
                  implementation
                </li>
              </UnorderedList>
            </Paragraph>
            <Paragraph>
              Contact us to discuss your needs: 
              <Styled.EmailLink href="mailto:info@wolfpackspacehub.com">
                info@wolfpackspacehub.com
              </Styled.EmailLink>
            </Paragraph>
          </div>
        </Columns>
      </Styled.Container>
    </Layout>
  );
};

export default EngineeringServices;

import React from 'react';
import * as Style from './ImageNoCaption.styled';

interface Props {
  altText?: string;
  imgStyle?: React.CSSProperties;
  src: string;
}

const ImageNoCaption: React.FunctionComponent<Props> = ({
  altText,
  imgStyle,
  src,
}: Props) => {
  return (
    <Style.Container>
      <Style.Image style={imgStyle} src={src} alt={altText} />
    </Style.Container>
  );
};

export default ImageNoCaption;
